import { IAddInquires } from '../../types';
export const inquiresJSON = {
  addInquires: ({
    name,
    email,
    phone,
    phoneCountry,
    message,
  }: IAddInquires) => {
    return {
      name: name,
      email: email,
      phone: phone,
      phoneCountry: phoneCountry,
      message: message,
    };
  },
};
