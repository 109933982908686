import { Link } from "react-router-dom";
import { IMAGES } from "../utils/staticJSON";

const Footer = () => {

    return (

        <footer className="pt-md-8 pt-6">
            <div className="bg-primary text-white">
                <div className="container py-9 pb-6 py-md-11 pb-md-8">
                    <div className="row gy-6 gy-lg-0">
                        <div className="col-lg-12">
                            <div className="text-center">
                                <img src={IMAGES.utradeLogoWhite} alt="" />
                            </div>
                        </div>
                        <div className="col">

                            <div className="widget mt-6">

                                <div className="d-flex flex-row flex-wrap justify-content-center align-items-center gap-lg-10 gap-8">

                                    <Link to="/" className="text-white">
                                        Home
                                    </Link>
                                    <Link to="/sellers" className="text-white">
                                        For sellers
                                    </Link>
                                    <Link to="/buyers" className="text-white">
                                        For buyers
                                    </Link>
                                    <Link to="about-us" className="text-white">
                                        About us
                                    </Link>
                                    <Link to="faqs" className="text-white">
                                        FAQ’s
                                    </Link>
                                    <Link to="contact-us" className="text-white">
                                        Contact us
                                    </Link>

                                </div>
                            </div>

                        </div>
                        <hr className="mt-6 mt-md-8 mb-5" />
                        <div className="d-md-flex align-items-center justify-content-between">

                            <p className="mb-2 mb-lg-0">@ 2023 All Rights Reserved | Terms & Conditions</p>

                            <nav className="nav social social-muted mb-0 text-md-end">
                                <a href="#">
                                    <img src={IMAGES.facebookIcon} alt="" />
                                </a>
                                <a href="#">
                                    <img src={IMAGES.linkedinIcon} alt="" />
                                </a>
                                <a href="#">
                                    <img src={IMAGES.twitterIcon} alt="" />
                                </a>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    );

}

export default Footer;