import { IMAGES } from '../utils/staticJSON';
import { Button, Offcanvas } from 'react-bootstrap';
import React, { useState } from 'react';
import { NavLink, useLocation } from "react-router-dom"
import { Link } from 'react-router-dom';

interface HeaderProps {
    showButton: boolean;
}

export const Header: React.FC<HeaderProps> = ({ showButton }) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const location = useLocation();

    const isContactUsPage = location.pathname === './contact-us'

    const [setButton, setShowButton] = useState(false);

    return (

        <header className="wrapper bg-light">
            <div className="bg-primary text-white fw-bold fs-15">
                <div className="container py-2 d-md-flex flex-md-row">
                    <div className="d-flex flex-row align-items-center">
                        <div className="icon text-white fs-22 me-2">
                            <img className='img-fluid' width={32} height={32} src={IMAGES.locationIcon} alt="" />
                        </div>
                        <address className="fs-16 fw-500 mb-0">Upanga, Dar Es Salaam, Tanzania</address>
                    </div>
                    <div className="d-flex flex-row align-items-center me-6 ms-auto">
                        <div className="icon text-white fs-22 me-2">
                            <img className='img-fluid' width={32} height={32} src={IMAGES.phoneIcon} alt="" />
                        </div>
                        <p className="fs-16 fw-500 mb-0">+255 765 786 086</p>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                        <div className="icon text-white fs-22 me-2">
                            <img className='img-fluid' width={32} height={32} src={IMAGES.emailIcon} alt="" />
                        </div>
                        <p className="fs-16 fw-500 mb-0">
                            <a href="" className="link-white hover">
                                <span className="__cf_email__">info@utrade.tz </span>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg center-nav navbar-light navbar-bg-light">

                <div className="container flex-lg-row flex-nowrap align-items-center">

                    <div className="navbar-brand">
                        <NavLink to="/">
                            <img className='' src={IMAGES.utradeLogo} alt="" />
                        </NavLink>
                    </div>

                    <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink to="/" className="nav-link">
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/sellers" className="nav-link">
                                    For sellers
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/buyers" className="nav-link">
                                    For buyers
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="about-us" className="nav-link">
                                    About us
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="faqs" className="nav-link">
                                    FAQ’s
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
                        <Offcanvas show={show} onHide={handleClose}>

                            <Offcanvas.Header className='offcanvas-header' closeButton>
                                <Link to="/">
                                    <img className='img-fluid' width={125} height={125} src={IMAGES.utradeLogoWhite} alt="" />
                                </Link>
                                <button
                                    type="button"
                                    className="btn-close btn-close-white text-white"
                                    onClick={handleShow}
                                />
                            </Offcanvas.Header>

                            <Offcanvas.Body className='offcanvas-body ms-lg-auto d-flex flex-column h-100'>
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <NavLink to="/" className="nav-link">
                                            Home
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/sellers" className="nav-link">
                                            For sellers
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/buyers" className="nav-link">
                                            For buyers
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="about-us" className="nav-link">
                                            About us
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="faqs" className="nav-link">
                                            FAQ’s
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        {showButton &&
                                            <NavLink to="contact-us" className="nav-link">
                                                Contact us
                                            </NavLink>}
                                    </li>
                                </ul>

                                {/* <div className="d-lg-none mt-auto pt-6 pb-6 order-4">
                                    <a href="mailto:first.last@email.com" className="link-inverse">
                                        info@email.com
                                    </a>
                                    <br /> 00 (123) 456 78 90 <br />
                                    <nav className="nav social social-white mt-4">
                                        <a href="#">
                                            <i className="uil uil-twitter" />
                                        </a>
                                        <a href="#">
                                            <i className="uil uil-facebook-f" />
                                        </a>
                                        <a href="#">
                                            <i className="uil uil-dribbble" />
                                        </a>
                                        <a href="#">
                                            <i className="uil uil-instagram" />
                                        </a>
                                        <a href="#">
                                            <i className="uil uil-youtube" />
                                        </a>
                                    </nav>
                                </div> */}
                            </Offcanvas.Body>
                        </Offcanvas>
                    </div>

                    <div className="navbar-other w-100 d-flex ms-auto">
                        <ul className="navbar-nav flex-row align-items-center ms-auto">
                            <li className="nav-item d-none d-md-block">
                                {showButton &&
                                    <NavLink to="contact-us" className="btn btn-primary">
                                        Contact us
                                    </NavLink>}
                            </li>
                            <li className="nav-item d-lg-none">
                                <button className="hamburger offcanvas-nav-btn" onClick={handleShow}>
                                    <span />
                                </button>
                            </li>
                        </ul>
                    </div>

                </div>

            </nav>
        </header>

    )

}

export default Header;