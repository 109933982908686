import React from 'react';
import { IMAGES } from '../utils/staticJSON';
import { Link, useNavigate } from 'react-router-dom';
import { Accordion, Button } from 'react-bootstrap';
import DownloadNow from './components/download-now';
import Testimonials from './components/testimonials';
const ForBuyers = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="content-wrapper">
        <section className="wrapper bg-gray mb-md-8 mb-6">
          <div className="container pt-11 pb-9">
            <div className="row gx-0 gy-10 align-items-center text-center text-lg-start">
              <div className="col-lg-6 col-xxl-5">
                {/* <h1 className='fs-22 fw-600 text-primary mb-3'>Heading of this section</h1> */}
                <h1 className="display-1 fs-55 fw-bold mb-3">
                  Discover, Procure, Succeed
                </h1>
                <p className="lead fs-20 fw-500 mb-7">
                  Our platform is designed to empower buyers like you with the
                  tools, resources, and opportunities you need to succeed in B2B
                  commerce. With U-Trade, you'll discover a wealth of products
                  and simplify your procurement process.
                </p>
                <div className="d-flex flex-row gap-3">
                  <a
                    href="https://apps.apple.com/us/app/u-trade-business/id6463854565"
                    target="_blank"
                  >
                    <img
                      className="img-fluid"
                      width={158}
                      height={50}
                      src={IMAGES.appleStore}
                      alt=""
                    />
                  </a>
                  <a
                    href=" 
https://play.google.com/store/apps/details?id=com.utrade.buyer"
                    target="_blank"
                  >
                    <img
                      className="img-fluid"
                      width={158}
                      height={50}
                      src={IMAGES.googleStore}
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 ms-auto">
                <figure>
                  <img
                    className="img-fluid"
                    src={IMAGES.buyerBG}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section className="container py-md-8 py-6">
          <div className="row text-center">
            <div className="col-md-11 col-lg-11 col-xl-10 col-xxl-8 mx-auto">
              <h2 className="display-4 fs-38 fw-bold mb-3 text-center">
                Features that Unlock Success Potential
              </h2>
              <p className="lead fs-20 fw-500 text-center mb-8">
                At U-Trade, we're dedicated to revolutionising your buying
                experience. With a vast array of quality options at your
                fingertips, your path to business growth begins here.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-5">
              <figure>
                <img
                  className="img-fluid"
                  width={287}
                  height={572}
                  src={IMAGES.buyerBG}
                  alt=""
                />
              </figure>
            </div>
            <div className="col-lg-6 offset-lg-1 ms-auto">
              <div className="d-flex flex-row mb-4">
                <div>
                  <div className="svg-bg svg-bg-lg rounded-xl me-5">
                    <img
                      className="img-fluid"
                      src={IMAGES.BuyersIcon1}
                      alt=""
                    />
                  </div>
                </div>
                <div>
                  <h3 className="fs-22">Vast Product Selection</h3>
                  <p className="fs-18">
                    Discover a diverse range of high-quality products from
                    trusted suppliers. U-Trade offers an extensive marketplace
                    where you can explore and procure products tailored to your
                    business needs.
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row mb-4">
                <div>
                  <div className="svg-bg svg-bg-lg rounded-xl me-5">
                    <img
                      className="img-fluid"
                      src={IMAGES.BuyersIcon2}
                      alt=""
                    />
                  </div>
                </div>
                <div>
                  <h3 className="fs-20">Effortless Order Tracking</h3>
                  <p className="fs-18">
                    Experience the convenience of tracking your orders with the
                    same ease and transparency as B2C shopping. Monitor the
                    status and delivery of your purchases in real-time, making
                    buying more efficient than ever.
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div>
                  <div className="svg-bg svg-bg-lg rounded-xl me-5">
                    <img
                      className="img-fluid"
                      src={IMAGES.BuyersIcon3}
                      alt=""
                    />
                  </div>
                </div>
                <div>
                  <h3 className="fs-20">Easy Cancellation and Refunds</h3>
                  <p className="fs-18">
                    Enjoy the peace of mind that comes with hassle-free order
                    cancellations and refunds. U-Trade's buyer-friendly policies
                    ensure a seamless experience, allowing you to make changes
                    to your orders when needed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="wrapper bg-gray my-md-8 my-6">
          <div className="container py-md-13 py-11">
            <div className="row  text-center">
              <div className="col-md-11 col-lg-11 col-xl-10 col-xxl-8 mx-auto">
                <h2 className="display-4 fs-38 fw-bold mb-3 text-center">
                  How It Works
                </h2>
                <p className="lead fs-20 fw-500 text-center mb-8">
                  We’ve made the entire B2B buying process as simple as it gets,
                  allowing you to focus on your business and not the
                  complexities of B2B supply chains and transactions.
                </p>
              </div>
            </div>
            <div className="row grid-section gx-md-6 gy-5 text-center">
              <div className="col-md-6 col-xl-4">
                <div className="card pt-2 pt-md-4">
                  <div className="card-body">
                    <div className="mb-6 mb-md-8">
                      <div className="d-md-block d-none">
                        <div className="btn btn-circle btn-lg w-15 h-15 btn-primary pe-none">
                          <span className="fs-38">01</span>
                        </div>
                      </div>
                      <div className="d-md-none">
                        <div className="btn btn-circle btn-lg w-13 h-13 btn-primary pe-none">
                          <span className="fs-30">01</span>
                        </div>
                      </div>
                    </div>
                    <h3 className="fs-22 fw-bold mb-3">
                      Explore Without Login
                    </h3>
                    <p className="fs-18 text-black">
                      Discover our vast range of products hassle-free. No login
                      is required! Explore, browse, and find the perfect items
                      for your business needs without any barriers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="card pt-2 pt-md-4">
                  <div className="card-body">
                    <div className="mb-6 mb-md-8">
                      <div className="d-md-block d-none">
                        <div className="btn btn-circle btn-lg w-15 h-15 btn-primary pe-none">
                          <span className="fs-38">02</span>
                        </div>
                      </div>
                      <div className="d-md-none">
                        <div className="btn btn-circle btn-lg w-13 h-13 btn-primary pe-none">
                          <span className="fs-30">02</span>
                        </div>
                      </div>
                    </div>
                    <h3 className="fs-22 fw-bold mb-3">
                      Quick Order and Payment
                    </h3>
                    <p className="fs-18 text-black">
                      When you're ready to make a purchase, simply login to your
                      account, make the payment and place an order with just a
                      few clicks, ensuring a quick buying process.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="card pt-2 pt-md-4">
                  <div className="card-body">
                    <div className="mb-6 mb-md-8">
                      <div className="d-md-block d-none">
                        <div className="btn btn-circle btn-lg w-15 h-15 btn-primary pe-none">
                          <span className="fs-38">03</span>
                        </div>
                      </div>
                      <div className="d-md-none">
                        <div className="btn btn-circle btn-lg w-13 h-13 btn-primary pe-none">
                          <span className="fs-30">03</span>
                        </div>
                      </div>
                    </div>
                    <h3 className="fs-22 fw-bold mb-3">Sit Back and Relax</h3>
                    <p className="fs-18 text-black">
                      Once your order is placed, U-Trade takes care of the
                      transportation and delivery process, ensuring your
                      purchases reach you promptly and securely.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-md-13 pt-11">
              <div className="col-xl-11 mx-auto">
                <div className="row align-items-center counter-wrapper gy-4 text-center">
                  <div className="col-6 col-lg-3">
                    <h3 className="counter fs-62">273+</h3>
                    <p className="fs-20 mt-3">Verified sellers</p>
                  </div>
                  <div className="col-6 col-lg-3">
                    <h3 className="counter fs-62">1000+</h3>
                    <p className="fs-20 mt-3">Verified buyers</p>
                  </div>
                  <div className="col-6 col-lg-3">
                    <h3 className="counter fs-62">75%</h3>
                    <p className="fs-20 mt-3">Yearly business growth</p>
                  </div>
                  <div className="col-6 col-lg-3">
                    <h3 className="counter fs-62">99%</h3>
                    <p className="fs-20 mt-3">Satisfaction ratio</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container py-md-8 py-6">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <h2 className="display-4 fs-38 fw-bold mb-8 text-center">
                Frequently Asked Questions{' '}
              </h2>
              {/* <p className="lead fs-20 fw-500 text-center mb-8">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et lorem ipsum dolor.</p> */}
            </div>
            <div className="col-lg-12">
              <Accordion
                className="accordion-wrapper custom"
              // defaultActiveKey="0"
              >
                <div className="row">
                  <div className="col-lg-6">
                    <Accordion.Item
                      className="card bg-gray border mb-3"
                      eventKey="0"
                    >
                      <Accordion.Header className="card-header fs-20 fw-600">
                        How Do I Create a Buyer Account on U-Trade?
                      </Accordion.Header>
                      <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                        You can easily create a buyer account on U-Trade by
                        following our registration process. Alternatively, you
                        can also request us to do that for you. Join our
                        platform to access a world of B2B commerce
                        opportunities.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="card bg-gray border mb-3"
                      eventKey="1"
                    >
                      <Accordion.Header className="card-header fs-20 fw-600">
                        What Types of Products can I Find on U-Trade?
                      </Accordion.Header>
                      <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                        U-Trade offers a diverse range of products from trusted
                        suppliers. Explore our catalogue to find everything from
                        food and clothing to electronics and industrial
                        equipment.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="card bg-gray border mb-3"
                      eventKey="2"
                    >
                      <Accordion.Header className="card-header fs-20 fw-600">
                        How Does U-Trade Ensure Secure Payments for Buyers?
                      </Accordion.Header>
                      <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                        We prioritise secure payment processing to protect
                        buyers. Our platform employs advanced security measures,
                        giving you confidence in your transactions.
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                  <div className="col-lg-6">
                    <Accordion.Item
                      className="card bg-gray border mb-3"
                      eventKey="4"
                    >
                      <Accordion.Header className="card-header fs-20 fw-600">
                        Can I Track My Orders As I Do in B2C Shopping?
                      </Accordion.Header>
                      <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                        Yes, you can! U-Trade provides order tracking features
                        similar to B2C shopping. Monitor your orders in
                        real-time for a hassle-free buying experience.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="card bg-gray border mb-3"
                      eventKey="5"
                    >
                      <Accordion.Header className="card-header fs-20 fw-600">
                        What is the Refund and Cancellation Policy on U-Trade?
                      </Accordion.Header>
                      <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                        We offer buyer-friendly refunds as well as cancellation
                        policies, enabling you to make decisions without any
                        hesitation. Learn more about{' '}
                        <Link
                          to=""
                          className="text-primary"
                        >
                          U-Trade's refund and cancellation policy
                        </Link>{' '}
                        to understand your options in case of changes to your
                        orders.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="card bg-gray border mb-3"
                      eventKey="6"
                    >
                      <Accordion.Header className="card-header fs-20 fw-600">
                        Is there a Registration Fee to Join U-Trade as a Buyer?
                      </Accordion.Header>
                      <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                        No, there is no registration fee to join U-Trade.
                        Getting started on our platform is simple and free. You
                        can register as a buyer without any upfront costs and
                        explore the benefits of our B2B commerce ecosystem.
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                </div>
              </Accordion>
            </div>
            <div className="col-lg text-center mt-md-5 mt-3">
              <Button
                type="button"
                className="btn btn-primary btn-lg"
                onClick={() => navigate('/faqs')}
              >
                View all
              </Button>
            </div>
          </div>
        </section>
        <section className="container py-md-8 py-6">
          <DownloadNow />
        </section>
        <section className="pt-md-8 pt-6">
          <div className="container">
            <Testimonials />
          </div>
        </section>
      </div>
    </>
  );
};
export default ForBuyers;
