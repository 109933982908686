import React, { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { IMAGES } from '../utils/staticJSON';
import clsx from 'clsx';
import { inquiresJSON } from '../api/apiJSON/contactInquires';
import { AddFeedback } from '../api/apiEndPoints';
import APICallService from '../api/apiCallService';
import { success } from '../Global/toast';
const ContactUs = () => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const staticText = 'Hello, Mohammed';
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState<{
    name: boolean;
    email: boolean;
    emailRegex: boolean;
    phone: boolean;
    message: boolean;
  }>({
    name: false,
    email: false,
    emailRegex: false,
    phone: false,
    message: false,
  });
  const [contactData, setContactData] = useState<{
    name: string;
    email: string;
    phone: string;
    phoneCountry: string;
    message: string;
  }>({
    name: '',
    email: '',
    phone: '',
    message: staticText,
    phoneCountry: '+255',
  });
  const handleDetailsChange = (name: string, value: string) => {
    let temp: any = { ...contactData };
    let tempValidation: any = { ...validation };
    if (name === 'email') {
      tempValidation.emailRegex = false;
    }
    tempValidation[name] = false;
    if (name == 'message') {
      if (value.length >= 15) {
        temp['message'] = value.trimStart();
      } else {
        temp['message'] = staticText;
        tempValidation[name] = true;
      }
    } else {
      if (!value.length) tempValidation[name] = true;
      temp[name] = value.trimStart();
    }
    setContactData(temp);
    setValidation(tempValidation);
  };
  const handleSubmit = async () => {
    let temp = { ...contactData };
    let validateTemp = { ...validation };
    if (temp.name === '') {
      validateTemp.name = true;
    }
    if (temp.email === '') {
      validateTemp.email = true;
    } else {
      if (isValidEmail(temp.email)) {
        console.log('Email is valid.');
      } else {
        validateTemp.emailRegex = true;
      }
    }
    if (temp.phone === '') {
      validateTemp.phone = true;
    }
    if (!(temp.message.trim().length > 15)) {
      validateTemp.message = true;
    }
    console.log(validateTemp);
    if (Object.values(validateTemp).every((val) => val === false)) {
      setLoading(true);
      let apiService = new APICallService(
        AddFeedback,
        inquiresJSON.addInquires(contactData)
      );
      let response = await apiService.callAPI();
      if (response) {
        setContactData({
          name: '',
          email: '',
          phone: '',
          message: staticText,
          phoneCountry: '+255',
        });
        success('Inquiry sent!');
      }
      setLoading(false);
    }
    setValidation(validateTemp);
  };
  const handleOnKeyPress = (event: any) => {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode === 46) {
      // Prevent the dot (.) character
      event.preventDefault();
    } else if (charCode < 48 || charCode > 57) {
      // Allow only numeric characters (0-9)
      event.preventDefault();
    }
  };
  const handleEmail = (event: any) => {
    let validateTemp = { ...validation };
    if (isValidEmail(event.target.value)) {
      console.log('Email is valid.');
    } else {
      validateTemp.emailRegex = true;
    }
    setValidation(validateTemp);
  };
  const isValidEmail = (email: any) => {
    return emailRegex.test(email);
  };
  return (
    <>
      <div className="content-wrapper">
        <section className="wrapper image-wrapper bg-soft-ash">
          <div className="container pt-12 pt-md-14 pb-5 pb-md-7 text-center">
            <div className="row">
              <div className="col-lg-10 col-xl-9 col-xxl-8 mx-auto">
                <h1
                  className="display-1 fs-48 fw-bold"
                  style={{
                    animationName: 'slideInDown',
                    animationDuration: '700ms',
                    animationTimingFunction: 'ease',
                    animationDelay: '0ms',
                    animationDirection: 'normal',
                    animationFillMode: 'both',
                  }}
                >
                  {' '}
                  Contact us
                </h1>
              </div>
            </div>
          </div>
          <div className="overflow-hidden">
            <div className="divider text-light mx-n2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 60"
              >
                <path
                  fill="currentColor"
                  d="M0,0V60H1440V0A5771,5771,0,0,1,0,0Z"
                />
              </svg>
            </div>
          </div>
        </section>
        <section className="container py-md-13 py-11">
          <div className="row justify-content-center">
            <div className="col-lg-11 col-xl-10">
              <h2 className="display-4 fs-38 fw-bold mb-3 text-center">
                We’re excited to help you on your journey!
              </h2>
              <p className="lead fs-20 fw-500 text-center mb-8">
                Leave us a little info, and we’ll be in touch.
              </p>
              <div className="row g-4">
                <div className="col-lg-4">
                  <Form.Control
                    className={clsx(
                      'form-control ',
                      validation.name ? 'border-danger' : ''
                    )}
                    type="text"
                    value={contactData.name}
                    placeholder="Full name"
                    name="name"
                    onChange={(e: any) => {
                      handleDetailsChange(e.target.name, e.target.value);
                    }}
                  />
                </div>
                {/* /column */}
                <div className="col-lg-4">
                  <Form.Control
                    className={clsx(
                      'form-control ',
                      validation.email || validation.emailRegex
                        ? 'border-danger'
                        : ''
                    )}
                    type="text"
                    placeholder="Email address"
                    value={contactData.email}
                    name="email"
                    onBlur={(e: any) => {
                      handleEmail(e);
                    }}
                    onChange={(e: any) => {
                      handleDetailsChange(e.target.name, e.target.value.trim());
                    }}
                  />
                  {validation.emailRegex ? (
                    <span className="fs-14 text-danger">
                      Please enter valid email
                    </span>
                  ) : (
                    <></>
                  )}{' '}
                </div>
                {/* /column */}
                <div className="col-lg-4">
                  <InputGroup>
                    <InputGroup.Text
                      className={clsx(
                        'bg-color-light2 border-end-0 ',
                        validation.email ? 'border-danger' : ''
                      )}
                      id="basic-addon1"
                    >
                      +255
                    </InputGroup.Text>
                    <Form.Control
                      className={clsx(
                        'form-control border-start-0 ',
                        validation.email ? 'border-danger' : ''
                      )}
                      placeholder="Phone number"
                      value={contactData.phone}
                      name="phone"
                      onChange={(e: any) => {
                        handleDetailsChange(e.target.name, e.target.value);
                      }}
                      onKeyPress={(event: any) => {
                        handleOnKeyPress(event);
                      }}
                    />
                  </InputGroup>
                </div>
                {/* /column */}
                <div className="col-12">
                  <Form.Control
                    className={clsx(
                      'form-control',
                      validation.message ? 'border-danger' : ''
                    )}
                    as="textarea"
                    value={contactData.message}
                    placeholder="Hello, Mohammed"
                    style={{ height: 150 }}
                    name="message"
                    onKeyDown={(event: any) => {
                      if (
                        event.keyCode === 8 &&
                        event.target.selectionStart === 0 &&
                        event.target.selectionEnd === 0
                      ) {
                        event.preventDefault(); // Prevent backspace key from removing "hii"
                      }
                    }}
                    onChange={(e: any) => {
                      handleDetailsChange(e.target.name, e.target.value);
                    }}
                  />
                </div>
                {/* /column */}
                <div className="col-12 text-center">
                  <Button
                    type="button"
                    className="btn btn-primary btn-send mt-2"
                    onClick={handleSubmit}
                  >
                    {!loading && (
                      <span className="indicator-label fs-16 fw-bold">
                        Send message
                      </span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress fs-16 fw-bold"
                        style={{ display: 'block' }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </Button>
                </div>
                {/* /column */}
              </div>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="row mb-14 mb-md-7">
            <div className="col-lg-12 mx-auto">
              <div className="card bg-primary pt-md-4 pt-2">
                <div className="card-body">
                  <div className="row align-items-start gy-6 gy-md-8 text-white">
                    <div className="col-lg-4 text-center">
                      <img
                        className="img-fluid mb-3"
                        width={55}
                        height={55}
                        src={IMAGES.locationIcon}
                        alt=""
                      />
                      <p className="fs-16 fw-500 mb-1">Address</p>
                      <h3 className="text-white fs-22">
                        Upanga, Dar Es Salaam, <br />
                        Tanzania
                      </h3>
                    </div>
                    <div className="col-lg-4 text-center">
                      <img
                        className="img-fluid mb-3"
                        width={55}
                        height={55}
                        src={IMAGES.emailIcon}
                        alt=""
                      />
                      <p className="fs-16 fw-500 mb-1">Email us</p>
                      <h3 className="text-white fs-22">info@utrade.tz</h3>
                    </div>
                    <div className="col-lg-4 text-center">
                      <img
                        className="img-fluid mb-3"
                        width={55}
                        height={55}
                        src={IMAGES.phoneIcon}
                        alt=""
                      />
                      <p className="fs-16 fw-500 mb-1">Call us on</p>
                      <h3 className="text-white fs-22">+255 765 786 086</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default ContactUs;
