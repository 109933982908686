export const IMAGES = {
    // Logos
    utradeLogo: require('../assets/media/utrade-logo.svg').default,
    utradeLogoWhite: require('../assets/media/logo-white.svg').default,
    utradeTruck: require('../assets/media/utrade-truck.webp'),

    // SVG's
    facebookIcon: require('../assets/media/facebook.svg').default,
    linkedinIcon: require('../assets/media/linkedin.svg').default,
    twitterIcon: require('../assets/media/twitter.svg').default,
    locationIcon: require('../assets/media/location.svg').default,
    phoneIcon: require('../assets/media/phone.svg').default,
    emailIcon: require('../assets/media/email.svg').default,
    BuyersIcon1: require('../assets/media/buyers_home.svg').default,
    BuyersIcon2: require('../assets/media/buyers_dashboard.svg').default,
    BuyersIcon3: require('../assets/media/buyers_shopping.svg').default,

    // Background-Images
    videoThumbnail: require('../assets/media/video-thumbnail.webp'),
    sellerBG: require('../assets/media/seller-bg.webp'),
    buyerBG: require('../assets/media/buyer-bg.webp'),
    linesBG: require('../assets/media/bg-lines.png'),
    downloadUtrade: require('../assets/media/download-utrade.png'),
    sellerInterTab: require('../assets/media/seller-inter-tab.webp'),
    sellerInter: require('../assets/media/seller-inter-large.webp'),
    sellerInter2: require('../assets/media/seller-inter-2.jpg'),

    // Mockup-Images
    macbookMockup1: require('../assets/media/macbook-mockup.png'),
    iphoneMockup1: require('../assets/media/iphone-mockup-2.png'),

    // Side-Images
    whoWeAre: require('../assets/media/who-we-are.webp'),
    whatWeDo: require('../assets/media/what-we-do.webp'),
    managementSales: require('../assets/media/management-and-sales.webp'),
    inventoryControl: require('../assets/media/inventory-control.webp'),

    // stores
    googleStore: require('../assets/media/google.png'),
    appleStore: require('../assets/media/appstore.png'),

    // sellers page
    ordersRoundChart: require('../assets/media/orders-round-chart.svg').default,
    advtRoundChart: require('../assets/media/advt-round-chart.svg').default,
    inventoryStock: require('../assets/media/inventory-stock.png'),
    salesChart: require('../assets/media/sales-chart.png'),
    productCard: require('../assets/media/product-card.png'),

    // avatar
    a1: require('../assets/media/a1.png'),
    a2: require('../assets/media/a2.png'),
    a3: require('../assets/media/a3.png'),
    a4: require('../assets/media/a4.png'),
}