import { Link } from 'react-router-dom';
import { IMAGES } from '../utils/staticJSON';
import DownloadNow from './components/download-now';
import Testimonials from './components/testimonials';
const AboutUs = () => {
  return (
    <>
      <div className="content-wrapper">
        <section className="wrapper image-wrapper bg-soft-ash mb-md-8 mb-6">
          <div className="container pt-12 pt-md-14 pb-5 pb-md-7 text-center">
            <div className="row">
              <div className="col-lg-10 col-xl-9 col-xxl-8 mx-auto">
                <h1
                  className="display-1 fs-48 fw-bold"
                  style={{
                    animationName: 'slideInDown',
                    animationDuration: '700ms',
                    animationTimingFunction: 'ease',
                    animationDelay: '0ms',
                    animationDirection: 'normal',
                    animationFillMode: 'both',
                  }}
                >
                  {' '}
                  About us
                </h1>
              </div>
            </div>
          </div>
          <div className="overflow-hidden">
            <div className="divider text-light mx-n2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 60"
              >
                <path
                  fill="currentColor"
                  d="M0,0V60H1440V0A5771,5771,0,0,1,0,0Z"
                />
              </svg>
            </div>
          </div>
        </section>
        <section className="container py-md-8 py-6">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-5 order-lg-2">
              <img
                className="img-fluid"
                src={IMAGES.whoWeAre as any}
                alt=""
              />
            </div>
            <div className="col-lg-7">
              <h2 className="display-4 fs-42 fw-bold text-black mb-md-2 mb-1">
                Who we are
              </h2>
              <p className="lead">
                Our journey began with a vision to revolutionise how businesses
                connect, trade, and prosper. Driven by this desire to simplify
                and streamline complex supply chains, we set out to create a
                robust and secure space where B2B buyers and sellers could
                interact directly, bypassing unnecessary intermediaries.
              </p>
              <p className="lead">
                Guided by the principles of transparency, trust, and
                transformation, we are a team of innovators and dreamers,
                committed to empowering both sellers and buyers to trade
                smarter, quicker, and more profitably.
              </p>
            </div>
          </div>
        </section>
        <section className="container py-md-8 py-6">
          <div className="card bg-primary">
            <div className="card-body p-9 p-xl-10">
              <div className="row">
                <div className="col-xl-11 mx-auto">
                  <div className="row align-items-center counter-wrapper gy-4 text-center">
                    <div className="col-6 col-lg-3">
                      <h3 className="counter fs-62 text-white">273+</h3>
                      <p className="fs-20 text-white mt-3">Verified sellers</p>
                    </div>
                    <div className="col-6 col-lg-3">
                      <h3 className="counter fs-62 text-white">1000+</h3>
                      <p className="fs-20 text-white mt-3">Verified buyers</p>
                    </div>
                    <div className="col-6 col-lg-3">
                      <h3 className="counter fs-62 text-white">75%</h3>
                      <p className="fs-20 text-white mt-3">
                        Yearly business growth
                      </p>
                    </div>
                    <div className="col-6 col-lg-3">
                      <h3 className="counter fs-62 text-white">99%</h3>
                      <p className="fs-20 text-white mt-3">
                        Satisfaction ratio
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container py-md-8 py-6">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-5">
              <img
                className="img-fluid"
                src={IMAGES.whatWeDo as any}
                alt=""
              />
            </div>
            <div className="col-lg-7">
              <h3 className="display-4 fs-42 fw-bold text-black mb-md-2 mb-1">
                What we do
              </h3>
              <p className="lead">
                At U-Trade, We understand the challenges businesses face in the
                traditional B2B trade model, and we are here to provide a
                solution. By connecting sellers, including importers and
                manufacturers, with a global audience of eager buyers, our
                dynamic marketplace removes around 6-7 intermediaries that are
                present in the traditional trade model, facilitating direct
                transactions and lowering costs.
              </p>
              <p className="lead">
                Furthermore, we offer a user-friendly interface that feels more
                like B2C shopping, making the procurement process intuitive and
                efficient. From showcasing products to tracking orders, we've
                streamlined every step of the trade journey for every party
                involved.
              </p>
            </div>
          </div>
        </section>
        <section className="py-md-8 py-6">
          <div className="wrapper bg-gray">
            <div className="container py-md-13 py-11">
              <div className="text-center">
                <h2 className="display-4 fs-38 fw-bold">
                  A platform that’s designed to benefit both ends of the{' '}
                  <br className="br" /> B2B supply chain.
                </h2>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="row gx-md-4 gx-xl-6 gy-4 pt-md-10 pt-8">
                    <div className="col-lg-6">
                      <div className="card testimonials justify-content-end bg-white text-center">
                        <div className="card-body pb-0 mt-md-4 mt-2">
                          <h3 className="h1 post-title fs-38 mb-3">
                            For Sellers
                          </h3>
                          <p className="fs-18 text-black">
                            U-Trade empowers sellers, including importers and
                            manufacturers, by providing a direct channel to
                            showcase and market your products, allowing
                            increased visibility and sales. Join us to enhance
                            your reach to buyers and boost your business.
                          </p>
                          <div className="mb-10">
                            <Link
                              to="/sellers"
                              className="more hover text-black"
                            >
                              Learn More
                            </Link>
                          </div>
                        </div>
                        <img
                          className="img-fluid"
                          src={IMAGES.macbookMockup1}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="card testimonials justify-content-end bg-white text-center">
                        <div className="card-body pb-0 mt-md-4 mt-2">
                          <h3 className="h1 post-title fs-38 mb-3">
                            For Buyers
                          </h3>
                          <p className="fs-18 text-black">
                            Buyers are at the forefront of our innovation.
                            Discover a vast marketplace of products from trusted
                            sellers and enjoy a user-friendly experience akin to
                            B2C shopping, making procurement effortless. Start
                            buying smarter with U-Trade.
                          </p>
                          <div className="mb-10">
                            <Link
                              to="/buyers"
                              className="more hover text-black"
                            >
                              Learn More
                            </Link>
                          </div>
                        </div>
                        <img
                          className="img-fluid"
                          src={IMAGES.iphoneMockup1}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container py-md-8 py-6">
          <div className="row text-center">
            <div className="col-md-11 col-lg-11 col-xl-10 col-xxl-8 mx-auto">
              <h2 className="display-4 fs-38 fw-bold mb-3 text-center">
                Why Choose U-Trade for Your B2B Needs
              </h2>
              <p className="lead fs-20 fw-500 text-center mb-8">
                Secure payments, reliable delivery, and the elimination of
                intermediaries for a more efficient and cost-effective B2B
                experience for all.
              </p>
            </div>
          </div>
          <div className="row gx-md-6 gy-5 grid-section text-center">
            <div className="col-md-6 col-xl-4">
              <div className="card bg-gray pt-2 pt-md-4">
                <div className="card-body">
                  <div className="mb-6 mb-md-8">
                    <div className="d-md-block d-none">
                      <div className="btn btn-circle btn-lg w-15 h-15 btn-white pe-none">
                        <span className="fs-38">01</span>
                      </div>
                    </div>
                    <div className="d-md-none">
                      <div className="btn btn-circle btn-lg w-13 h-13 btn-white pe-none">
                        <span className="fs-30">01</span>
                      </div>
                    </div>
                  </div>
                  <h3 className="fs-22 fw-bold mb-3">
                    Secure and Smooth Payments
                  </h3>
                  <p className="fs-18 text-black">
                    Sellers can trust U-Trade for their B2B needs because we
                    prioritise secure and seamless payments. Our platform
                    ensures that payments are processed with the utmost
                    security, providing sellers with peace of mind while
                    conducting business transactions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="card bg-gray pt-2 pt-md-4">
                <div className="card-body">
                  <div className="mb-6 mb-md-8">
                    <div className="d-md-block d-none">
                      <div className="btn btn-circle btn-lg w-15 h-15 btn-white pe-none">
                        <span className="fs-38">02</span>
                      </div>
                    </div>
                    <div className="d-md-none">
                      <div className="btn btn-circle btn-lg w-13 h-13 btn-white pe-none">
                        <span className="fs-30">02</span>
                      </div>
                    </div>
                  </div>
                  <h3 className="fs-22 fw-bold mb-3">
                    Reliable Shipping and Delivery
                  </h3>
                  <p className="fs-18 text-black">
                    Buyers trust U-Trade because we prioritise the safe and
                    reliable transport of goods. Our streamlined delivery
                    process ensures that your purchases reach you in perfect
                    condition and on time. From order placement to delivery,
                    your products are handled with utmost care.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="card bg-gray pt-2 pt-md-4">
                <div className="card-body">
                  <div className="mb-6 mb-md-8">
                    <div className="d-md-block d-none">
                      <div className="btn btn-circle btn-lg w-15 h-15 btn-white pe-none">
                        <span className="fs-38">03</span>
                      </div>
                    </div>
                    <div className="d-md-none">
                      <div className="btn btn-circle btn-lg w-13 h-13 btn-white pe-none">
                        <span className="fs-30">03</span>
                      </div>
                    </div>
                  </div>
                  <h3 className="fs-22 fw-bold mb-3">
                    Eliminating Intermediaries for a Win-Win
                  </h3>
                  <p className="fs-18 text-black">
                    At U-Trade, we've eliminated 6-7 intermediaries from the
                    traditional supply chain, becoming a direct bridge between
                    suppliers and buyers. By reducing intermediaries, we have
                    cut costs, promoted efficiency, and created a win-win
                    situation for both sellers and buyers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container py-md-8 py-6">
          <div
            className="wrapper image-wrapper bg-full bg-image bg-primary rounded-4 d-md-block d-none"
            style={{
              backgroundImage: `url(${IMAGES.downloadUtrade})`,
              backgroundPositionY: 'bottom',
            }}
          >
            <div className="p-10 py-lg-12 py-xl-13">
              <div className="row text-white gx-0 gy-10">
                <div className="col-md-10 offset-lg-0 col-lg-6 col-xl-6">
                  <h2 className="display-3 fs-38 fw-bold mb-2 text-white text-start">
                    Revolutionise your B2B journey and build a profitable
                    future.
                  </h2>
                  <p className="lead fs-18 fw-500">
                    Download the U-Trade app today and experience the change for
                    yourself!
                  </p>
                  <div className="d-flex flex-row gap-3">
                    <a
                      href="https://apps.apple.com/us/app/u-trade-business/id6463854565"
                      target="_blank"
                    >
                      <img
                        className="img-fluid"
                        width={158}
                        height={50}
                        src={IMAGES.appleStore}
                        alt=""
                      />
                    </a>
                    <a
                      href=" 
https://play.google.com/store/apps/details?id=com.utrade.buyer"
                      target="_blank"
                    >
                      <img
                        className="img-fluid"
                        width={158}
                        height={50}
                        src={IMAGES.googleStore}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper bg-primary rounded-4 d-md-none">
            <div className="p-10 py-lg-12 py-xl-13">
              <div className="row text-white gx-0 gy-10">
                <div className="col-md-10 offset-lg-0 col-lg-6 col-xl-6">
                  <h2 className="display-3 fs-38 fw-bold mb-2 text-white text-start">
                    Revolutionise your B2B journey and build a profitable
                    future.
                  </h2>
                  <p className="lead fs-18 fw-500">
                    Download the U-Trade app today and experience the change for
                    yourself!
                  </p>
                  <div className="d-flex flex-sm-row flex-column gap-3">
                    <a
                      href="https://apps.apple.com/us/app/u-trade-business/id6463854565"
                      target="_blank"
                    >
                      <img
                        className="img-fluid"
                        width={158}
                        height={50}
                        src={IMAGES.appleStore}
                        alt=""
                      />
                    </a>
                    <a
                      href=" 
https://play.google.com/store/apps/details?id=com.utrade.buyer"
                      target="_blank"
                    >
                      <img
                        className="img-fluid"
                        width={158}
                        height={50}
                        src={IMAGES.googleStore}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container pt-md-8 pt-6">
          <Testimonials />
        </section>
      </div>
    </>
  );
};
export default AboutUs;
