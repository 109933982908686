import { Suspense } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import Headroom from 'react-headroom';
import AppRoutes from './routes/AppRoutes';
import ScrollToTop from 'react-scroll-to-top';
import { useLocation } from 'react-router-dom';
const App = () => {
  const location = useLocation();
  const showButton = location.pathname !== '/contact-us';
  console.log = () => { };
  console.error = () => { };
  console.debug = () => { };
  console.warn = () => { };
  return (
    <Suspense fallback={'Loading...'}>
      {/* <ScrollToTop
        smooth
        color="#1b74e4"
        height={'20px'}
        width="20px"
      /> */}
      {/* header */}
      <Headroom style={{ zIndex: 999 }}>
        <Header showButton={showButton} />
      </Headroom>
      {/* pages */}
      <div style={{ minHeight: '85vh' }}>
        <AppRoutes />
      </div>
      {/* footer */}
      <Footer />
    </Suspense>
  );
};
export default App;
