import { Link } from 'react-router-dom';
import { IMAGES } from '../../utils/staticJSON';
const InnerContent = () => {
  return (
    <div className="p-10 py-lg-12 py-xl-13">
      <div className="row text-white gx-0 gy-10">
        <div className="col-md-10 offset-lg-0 col-lg-6 col-xl-6">
          <h2 className="display-3 fs-38 fw-bold mb-2 text-white text-start">
            Want to buy quality products for your retail business?
          </h2>
          <p className="lead fs-18 fw-500">
            Download our U-Trade app today and discover a vast array of
            high-quality products and a seamless buying process.
          </p>
          <div className="d-flex flex-row gap-3">
            <a
              href="https://apps.apple.com/us/app/u-trade-business/id6463854565"
              target="_blank"
            >
              <img
                className="img-fluid"
                width={158}
                height={50}
                src={IMAGES.appleStore}
                alt=""
              />
            </a>
            <a
              href=" 
https://play.google.com/store/apps/details?id=com.utrade.buyer"
              target="_blank"
            >
              <img
                className="img-fluid"
                width={158}
                height={50}
                src={IMAGES.googleStore}
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
const DownloadNow = () => {
  return (
    <>
      <div
        className="wrapper image-wrapper bg-full bg-image bg-primary rounded-4 d-md-block d-none"
        style={{
          backgroundImage: `url(${IMAGES.downloadUtrade})`,
          backgroundPositionY: 'bottom',
        }}
      >
        <InnerContent />
      </div>
      <div className="wrapper bg-primary rounded-4 d-md-none">
        <InnerContent />
      </div>
    </>
  );
};
export default DownloadNow;
