import React from 'react';
import { IMAGES } from '../utils/staticJSON';
import { Accordion, Button, Form, InputGroup } from 'react-bootstrap';
import { Splide, SplideSlide } from '@splidejs/react-splide';
// splider >> CSS
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/sea-green';
import '@splidejs/react-splide/css/core';
import SellerTestimonials from './components/seller_testimonials';
import { Link, useNavigate } from 'react-router-dom';
const ForSellers = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="content-wrapper">
        <section className="wrapper bg-light position-relative min-vh-100 d-lg-flex align-items-center">
          <div
            className="col-xxl-8 col-lg-7 order-lg-2 position-lg-absolute top-0 end-0 image-wrapper bg-image bg-cover h-100"
            style={{
              backgroundImage: `url(${IMAGES.sellerBG})`,
              backgroundPositionX: 'left',
            }}
          ></div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xxl-4 col-lg-5">
                <div className="mt-10 mt-md-11 px-md-0 text-center text-lg-start">
                  {/* <h1 className='fs-22 fw-600 text-primary'>Heading of this section</h1> */}
                  <h1 className="display-1 fs-55 fw-bold my-3">
                    Boost Sales, Maximise Profits
                  </h1>
                  <p className="lead fs-20 fw-500 mb-6">
                    At U-Trade, we're dedicated to your success as a seller. Our
                    platform is built to turbocharge your business. With us,
                    you'll tap into a network of potential buyers and gain
                    access to features designed to maximise your selling
                    potential.
                  </p>
                  <div className="d-flex justify-content-center justify-content-lg-start">
                    <Link
                      type="button"
                      className="btn btn-lg btn-primary"
                      to="http://business.utrade.tz/"
                      target="_blank"
                    >
                      Register as seller now!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container pb-md-8 pt-md-13 pb-6 pt-11">
          <div className="row text-center">
            <div className="col-lg-11 col-xl-10 col-xxl-9 mx-auto">
              <h2 className="display-4 fs-38 fw-bold mb-2 text-center">
                Features that Drive Business Growth
              </h2>
              <p className="lead fs-20 fw-500 text-center mb-8">
                Understanding the decades-long struggle of suppliers, we have
                incorporated features that will empower you to grow your
                business while making informed decisions.
              </p>
            </div>
          </div>
          <div className="row grid-section g-3">
            <div className="col-lg-8">
              <div className="row g-3">
                <div className="col-md-5 col-lg-5 col-xxl-4">
                  <div className="card bg-gray">
                    <div className="card-body p-lg-6 p-5 pb-lg-3">
                      <div className="">
                        <h3 className="fs-25 fw-bold">Orders</h3>
                        <p className="fs-18 fw-500 text-black">
                          Track and manage orders with precision and ease
                        </p>
                      </div>
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <div className="d-flex flex-column text-start">
                          <span className="fs-25 text-black lh-1 fw-bold">
                            168
                          </span>
                          <span className="fs-16 fw-500">Last month: 76</span>
                        </div>
                        <div className="">
                          <img
                            className="img-fluid"
                            src={IMAGES.ordersRoundChart}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 col-lg-7 col-xxl-8">
                  <div className="card bg-gray">
                    <div className="card-body p-lg-6 p-5 pb-lg-3">
                      <div className="">
                        <h3 className="fs-25 fw-bold">Inventory Stock</h3>
                        <p className="fs-18 fw-500 text-black">
                          View and maintain control over your product stocks
                        </p>
                      </div>
                      <div className="">
                        <img
                          className="img-fluid"
                          src={IMAGES.inventoryStock}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 col-lg-7 col-xxl-8">
                  <div className="card bg-gray">
                    <div className="card-body p-lg-6 p-5 pb-lg-3">
                      <div className="">
                        <h3 className="fs-25 fw-bold">Product Sales</h3>
                        <p className="fs-18 fw-500 text-black">
                          Access comprehensive sales statistics to make informed
                          decisions.
                        </p>
                      </div>
                      <div className="">
                        <img
                          className="img-fluid"
                          src={IMAGES.salesChart}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 col-lg-5 col-xxl-4">
                  <div className="card bg-gray">
                    <div className="card-body p-lg-6 p-5 pb-lg-3">
                      <div className="">
                        <h3 className="fs-25 fw-bold">Advertisement</h3>
                        <p className="fs-18 fw-500 text-black">
                          Boost visibility and sales with targeted ad campaigns.
                        </p>
                      </div>
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <div className="d-flex flex-column text-start">
                          <span className="fs-25 text-black lh-1 fw-bold">
                            10
                          </span>
                          <span className="fs-16 fw-500">Last month: 4</span>
                        </div>
                        <div className="">
                          <img
                            className="img-fluid"
                            src={IMAGES.advtRoundChart}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card bg-gray">
                <div className="card-body p-lg-6 p-5">
                  <div className="row">
                    <div className="col-lg-12 col-sm-8 col-6">
                      <h3 className="fs-25 fw-bold">Products</h3>
                      <p className="fs-18 fw-500 text-black">
                        Effortlessly list and manage a diverse range of products
                      </p>
                    </div>
                    <div className="col-lg-12 col-sm-4 col-6 position-relative">
                      <div
                        className="shape rounded-4 rellax d-md-block d-none"
                        data-rellax-speed={0}
                        style={{
                          backgroundColor: '#e0e0df',
                          bottom: '-0.60rem',
                          right: '0.80rem',
                          width: '90%',
                          height: '98%',
                          zIndex: 1,
                          transform: 'translate3d(0px, 0px, 0px)',
                        }}
                      />
                      <div
                        className="shape rounded-4 rellax d-md-block d-none"
                        data-rellax-speed={0}
                        style={{
                          backgroundColor: '#e0e0df50',
                          bottom: '-1rem',
                          right: '1.5rem',
                          width: '80%',
                          height: '98%',
                          zIndex: 0,
                          transform: 'translate3d(0px, 0px, 0px)',
                        }}
                      />
                      <div className="card border-0 z-1">
                        <figure className="card-img-top rounded-4">
                          <img
                            className="img-fluid rounded-3"
                            src={IMAGES.productCard}
                            alt=""
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="wrapper bg-gray my-md-8 my-6">
          <div className="container py-md-8 py-6">
            <div className="row pt-md-8 pt-6 text-center">
              <div className="col-md-11 col-lg-11 col-xl-10 col-xxl-8 mx-auto">
                <h2 className="display-4 fs-38 fw-bold mb-2 text-center">
                  Let’s understand how you can benefit from these!
                </h2>
                <p className="lead fs-20 fw-500 text-center mb-8">
                  At U-Trade, we offer a comprehensive suite of tools to help
                  you showcase your products, manage them and their sales, and
                  advertise them to a broad range of buyers.
                </p>
              </div>
            </div>
            <div className="py-md-8 py-6">
              <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                <div className="col-lg-6">
                  <img
                    className="img-fluid"
                    src={IMAGES.managementSales as any}
                    alt=""
                  />
                </div>
                <div className="col-lg-6">
                  <h3 className="display-4 fs-42 fw-bold text-black mb-md-2 mb-1">
                    Streamline your product management and sales strategy
                  </h3>
                  <p className="lead">
                    You can effortlessly upload and manage a diverse range of
                    products, all while keeping a close eye on their sales
                    performance. With access to detailed sales statistics,
                    you'll have the information you need to make data-driven
                    decisions and refine your approach. Additionally, our
                    advertising capabilities enable you to boost visibility and
                    sales through targeted ad campaigns, while tracking their
                    performance in real-time. U-Trade provides a seamless
                    platform where product listing, sales management, and
                    advertising come together to drive your success.
                  </p>
                </div>
              </div>
            </div>
            <div className="py-md-8 py-6">
              <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                <div className="col-lg-6 order-lg-2">
                  <img
                    className="img-fluid"
                    src={IMAGES.inventoryControl as any}
                    alt=""
                  />
                </div>
                <div className="col-lg-6">
                  <h3 className="display-4 fs-42 fw-bold text-black mb-md-2 mb-1">
                    Order management and inventory control for your business
                  </h3>
                  <p className="lead">
                    With our platform, you can track and manage orders with
                    precision and ease, ensuring a smooth and efficient workflow
                    from order placement to fulfilment. Concurrently, our
                    inventory management tools grant you a comprehensive view of
                    your product stocks, allowing you to maintain control and
                    optimise stock levels effortlessly. This integrated approach
                    empowers you to provide top-notch customer service, reduce
                    overheads, and streamline your operations, all within the
                    U-Trade ecosystem.
                  </p>
                </div>
              </div>
            </div>
            <Splide
              className="px-0 py-md-8 py-6 d-md-block d-none"
              options={{
                height: 'auto',
                arrows: false,
                autoWidth: true,
                gap: '1.5rem',
                perPage: 2,
                pagination: false,
                focus: 'center',
                trimSpace: false,
                breakpoints: {
                  640: {
                    perPage: 1,
                    pagination: true,
                  },
                  767: {
                    perPage: 1,
                    pagination: true,
                  },
                  992: {
                    perPage: 1,
                    pagination: true,
                  },
                  1024: {
                    perPage: 2,
                    pagination: true,
                  },
                },
              }}
            >
              <SplideSlide>
                <div>
                  <img
                    width={915}
                    height={550}
                    className="img-fluid rounded-4"
                    src={IMAGES.sellerInter}
                    alt=""
                  />
                </div>
              </SplideSlide>
              <SplideSlide>
                <div>
                  <img
                    width={915}
                    height={550}
                    className="img-fluid rounded-4"
                    src={IMAGES.sellerInter2}
                    alt=""
                  />
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </section>
        <section className="container py-md-8 py-6">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <h2 className="display-4 fs-38 fw-bold mb-8 text-center">
                Frequently Asked Questions{' '}
              </h2>
              {/* <p className="lead fs-20 fw-500 text-center mb-8">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et lorem ipsum dolor.</p> */}
            </div>
            <div className="col-lg-12">
              <Accordion
                className="accordion-wrapper custom"
              // defaultActiveKey="0"
              >
                <div className="row">
                  <div className="col-lg-6">
                    <Accordion.Item
                      className="card border bg-gray mb-3"
                      eventKey="0"
                    >
                      <Accordion.Header className="card-header fs-20 fw-600">
                        How Do I Register as a Seller on U-Trade?
                      </Accordion.Header>
                      <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                        Get started on U-Trade by completing our simple seller
                        registration process. It’s quick and straightforward.
                        Alternatively, you can also request us to create an
                        account for you. Join our community of successful
                        sellers today.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="card border bg-gray mb-3"
                      eventKey="1"
                    >
                      <Accordion.Header className="card-header fs-20 fw-600">
                        Can I Sell on U-Trade Without a Website?
                      </Accordion.Header>
                      <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                        Absolutely! You don't need a website to start selling on
                        U-Trade. We provide a user-friendly platform to
                        showcase, advertise and sell your products.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="card border bg-gray mb-3"
                      eventKey="2"
                    >
                      <Accordion.Header className="card-header fs-20 fw-600">
                        Who Manages the Shipping Process?
                      </Accordion.Header>
                      <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                        U-Trade takes care of the shipping process for you. We
                        ensure secure and reliable delivery, allowing you to
                        focus on growing your business.
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                  <div className="col-lg-6">
                    <Accordion.Item
                      className="card border bg-gray mb-3"
                      eventKey="4"
                    >
                      <Accordion.Header className="card-header fs-20 fw-600">
                        How Do I List My Products on U-Trade?
                      </Accordion.Header>
                      <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                        Listing your products on U-Trade is easy. Follow the
                        simple instructions on the listing page, and if you face
                        any problems feel free to connect with us. Use our
                        intuitive platform to showcase your offerings and reach
                        a wider audience.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="card border bg-gray mb-3"
                      eventKey="5"
                    >
                      <Accordion.Header className="card-header fs-20 fw-600">
                        What Strategies can I Implement to Grow My Business on
                        U-Trade?
                      </Accordion.Header>
                      <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                        Explore our seller resources and tips to maximise your
                        success on U-Trade. We provide insights and tools to
                        help you expand your business. Furthermore, you can
                        create targeted advertisements and reach a wider
                        audience more effectively.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="card border bg-gray mb-3"
                      eventKey="6"
                    >
                      <Accordion.Header className="card-header fs-20 fw-600">
                        Is there a Fee for Registering as a Seller on U-Trade?
                      </Accordion.Header>
                      <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                        Registration as a seller on U-Trade is free! You can
                        join our platform and start selling without any upfront
                        fees.
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                </div>
              </Accordion>
            </div>
            <div className="col-lg text-center mt-md-5 mt-3">
              <Button
                type="button"
                className="btn btn-primary btn-lg"
                onClick={() => navigate('/faqs')}
              >
                View all
              </Button>
            </div>
          </div>
        </section>
        <section className="container py-md-8 py-6">
          <div
            className="card rounded-4 image-wrapper bg-full bg-image bg-primary"
            style={{ backgroundImage: `url(${IMAGES.linesBG})` }}
          >
            <div className="card-body p-9 p-xl-11">
              <div className="row justify-content-lg-between align-items-center gy-6">
                <div className="col-lg-8">
                  <h3 className="display-5 text-white">
                    Want to reach new potential buyers and boost your sales?
                  </h3>
                  <p className="lead pe-lg-12 mb-0 text-white">
                    Make a seller account on U-Trade today and start enjoying
                    growth-driven seller benefits.
                  </p>
                </div>
                <div className="col-lg-auto col-xl-auto">
                  <Link
                    type="button"
                    className="btn btn-white"
                    to="http://business.utrade.tz/"
                    target="_blank"
                  >
                    Register as a seller now!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-md-8 pt-6">
          <div className="container">
            <SellerTestimonials />
          </div>
        </section>
      </div>
    </>
  );
};
export default ForSellers;
