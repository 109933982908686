import { IMAGES } from '../../utils/staticJSON';
import { Splide, SplideSlide } from '@splidejs/react-splide';
// splider >> CSS
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/sea-green';
import '@splidejs/react-splide/css/core';
const SellerTestimonials = () => {
    return (
        <>
            <div className="row justify-content-center">
                <div className="col-lg-11">
                    <h2 className="display-4 fs-38 fw-bold mb-2 text-center">Eliminate inefficiencies and foster partnerships that drive success</h2>
                    <p className="lead fs-20 fw-500 mb-2 text-center">Read how our sellers and buyers have benefitted from our platform, taking their business to new heights of success.</p>
                </div>
                <div className="col-lg-12">
                    <Splide
                        className='px-0 py-md-8 py-6'
                        options={{
                            arrows: false,
                            height: 'auto',
                            autoplay: true,
                            rewind: true,
                            gap: '1rem',
                            perPage: 3,
                            pagination: false,
                            breakpoints: {
                                640: {
                                    perPage: 1,
                                    pagination: true,
                                },
                                767: {
                                    perPage: 2,
                                    pagination: true,
                                },
                                992: {
                                    perPage: 1,
                                    pagination: true,
                                },
                                1024: {
                                    perPage: 2,
                                    pagination: true,
                                }
                            },
                            updateOnMove: true,
                        }}
                    >
                        <SplideSlide>
                            <div className="card testimonials">
                                <div className="card-body px-6 pt-6 pb-0">
                                    <blockquote className="border-0 mb-0">
                                        <p className='fs-18 text-black'>
                                            From the very beginning, Freedom Traders has stood hand in hand with U-Trade, and it's been an extraordinary journey. Your platform's unwavering support, user-friendliness, and dedication to customer satisfaction have been instrumental in our partnership. We extend our heartfelt gratitude for being our trusted partner.
                                        </p>
                                    </blockquote>
                                </div>
                                <div className="card-footer border-top-0 pt-0 px-6 pb-6">
                                    <div className="blockquote-details">
                                        <img
                                            className="rounded-circle border w-11"
                                            src={IMAGES.a4} alt=""
                                        />
                                        <div className="info">
                                            <h5 className="fs-16 mb-0">Ali khaki
                                            </h5>
                                            <h5 className="fs-16 mb-0"> Director - Freedom Traders</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SplideSlide>
                        <SplideSlide>
                            <div className="card testimonials">
                                <div className="card-body px-6 pt-6 pb-0">
                                    <blockquote className="border-0 mb-0">
                                        <p className='fs-18 text-black'>
                                            From the fields to your kitchen, Kilombero Sugar Company and U-Trade have joined hands in an exceptional journey. U-Trade's innovative platform, paired with Kilombero's quality sugar products, has made every household sweeter. Together, we're stirring up success and sweetening lives across the board. Thank you, U-Trade, for being our indispensable partner in this flavorful journey.
                                        </p>
                                    </blockquote>
                                </div>
                                <div className="card-footer border-top-0 pt-0 px-6 pb-6">
                                    <div className="blockquote-details">
                                        <img
                                            className="rounded-circle border w-11"
                                            src={IMAGES.a2} alt=""
                                        />
                                        <div className="info">
                                            <h5 className="fs-16 mb-0">Johanes Rugalema
                                            </h5>
                                            <h5 className="fs-16 mb-0"> Distribution Manager - Kilombero sugar</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SplideSlide>
                        <SplideSlide>
                            <div className="card testimonials">
                                <div className="card-body px-6 pt-6 pb-0">
                                    <blockquote className="border-0 mb-0">
                                        <p className='fs-18 text-black'>
                                            U-Trade has been instrumental in elevating Darsh Industries, where we specialize in crafting exquisite sauces, jams, and gourmet food products. Their exceptional platform support, combined with our dedication to quality, has catapulted us into a position of significance within the industry. Looking ahead, we are excited to work hand-in-hand with U-Trade on future endeavors to ensure seamless distribution and continued satisfaction for our valued customers
                                        </p>
                                    </blockquote>
                                </div>
                                <div className="card-footer border-top-0 pt-0 px-6 pb-6">
                                    <div className="blockquote-details">
                                        <img
                                            className="rounded-circle border w-11"
                                            src={IMAGES.a3} alt=""
                                        />
                                        <div className="info">
                                            <h5 className="fs-16 mb-0">Darsh Pandit
                                            </h5>
                                            <h5 className="fs-16 mb-0"> Director - Darsh Industries</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SplideSlide>
                        <SplideSlide>
                            <div className="card testimonials">
                                <div className="card-body px-6 pt-6 pb-0">
                                    <blockquote className="border-0 mb-0">
                                        <p className='fs-18 text-black'>
                                            Africa Harmony, the largest commodity products manufacturer in Tanzania, stands tall in partnership with U-Trade. Their platform has not only contributed significantly to our reach and growth but has also accentuated our role as an industry leader. U-Trade's commitment to facilitating commerce and connecting us with a broader audience ensures a promising future as we continue to serve our community and nation.
                                        </p>
                                    </blockquote>
                                </div>
                                <div className="card-footer border-top-0 pt-0 px-6 pb-6">
                                    <div className="blockquote-details">
                                        <img
                                            className="rounded-circle border w-11"
                                            src={IMAGES.a4} alt=""
                                        />
                                        <div className="info">
                                            <h5 className="fs-16 mb-0">Tima Vostro</h5>
                                            <h5 className="fs-16 mb-0">Distribution Manager - Africa Harmony</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SplideSlide>
                        <SplideSlide>
                            <div className="card testimonials">
                                <div className="card-body px-6 pt-6 pb-0">
                                    <blockquote className="border-0 mb-0">
                                        <p className='fs-18 text-black'>
                                            GSM Group's prominence has been further accentuated through our association with U-Trade. Their platform's efficiency and steadfast customer-centric approach have not merely played a pivotal role in our online expansion; rather, they have elevated GSM Group to an even more significant stature. This partnership is a testament to our unwavering commitment to excellence and leadership.
                                        </p>
                                    </blockquote>
                                </div>
                                <div className="card-footer border-top-0 pt-0 px-6 pb-6">
                                    <div className="blockquote-details">
                                        <img
                                            className="rounded-circle border w-11"
                                            src={IMAGES.a1} alt=""
                                        />
                                        <div className="info">
                                            <h5 className="fs-16 mb-0">Zainab</h5>
                                            <h5 className="fs-16 mb-0">CEO - GSM Group</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SplideSlide>
                    </Splide>
                </div>
            </div>
        </>
    )
}
export default SellerTestimonials;