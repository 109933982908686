import React, { useRef, useEffect } from 'react';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

interface VideoPlayerProps {
    videoSource: string;
    posterSource: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoSource, posterSource }) => {

    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        if (videoRef.current) {
            const player = new Plyr(videoRef.current);
        }
    }, []);

    return (
        <video poster={posterSource} className={'w-100 rounded caption-overlay d-block'} ref={videoRef} controls autoPlay loop playsInline muted>
            <source src={videoSource} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    );
};

export default VideoPlayer;