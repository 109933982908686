import { IMAGES } from '../utils/staticJSON';
import VideoPlayer from '../custom/VideoPlayer';
import { Accordion, Button } from 'react-bootstrap';
import Testimonials from './components/testimonials';
import { Link } from 'react-router-dom';
const Home = () => {
  return (
    <>
      <div className="content-wrapper">
        <section className="wrapper bg-light">
          <div className="container pt-12 pt-md-14 pb-11 pb-md-19 pb-lg-22 text-center">
            <div className="row">
              <div className="col-lg-10 col-xl-11 col-xxl-8 mx-auto">
                {/* <h1 className="fs-22 fw-600 text-primary">Heading of this section will be here</h1> */}
                <h1 className="display-1 fs-62 mx-lg-n10 mx-xl-0 mb-5">
                  Your Path to Smarter, Simpler and Streamlined B2B Commerce
                </h1>
                <p className="lead fs-20 px-md-12 px-lg-0 mx-lg-n10 mx-xl-0 mb-8">
                  Say farewell to complex supply chains and unnecessary
                  intermediaries, and embark on a journey towards profitable,
                  direct B2B partnerships. Whether you’re a supplier looking for
                  retailers or a retailer looking for reliable manufacturers or
                  importers, connecting with the right person and conducting
                  transactions have never been this easy!
                </p>
                <Link
                  to="/about-us"
                  className="btn btn-lg btn-primary btn-icon btn-icon-end"
                >
                  {' '}
                  Read more{' '}
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="wrapper py-md-8 py-6">
          <div className="bg-gray">
            <div className="container pt-15 pt-md-17 pb-11 pb-md-13">
              <figure className="rounded mt-md-n21 mt-lg-n23 mb-13 position-relative">
                <div style={{ maxHeight: '546px', height: '546px' }}>
                  <VideoPlayer
                    posterSource={IMAGES.videoThumbnail}
                    videoSource=""
                  />
                </div>
              </figure>
              <div className="row">
                <div className="col-md-10 col-lg-8 col-xl-8 col-xxl-6 mx-auto text-center">
                  <h2 className="display-4 fs-38 fw-bold">
                    A platform that’s designed to benefit both ends of the B2B
                    supply chain.
                  </h2>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="row gx-md-4 gx-xl-6 gy-4 pt-md-10 pt-8">
                    <div className="col-lg-6">
                      <div className="card testimonials justify-content-end bg-white text-center">
                        <div className="card-body pb-0 mt-md-4 mt-2">
                          <h3 className="h1 post-title fs-38 mb-3">
                            For Sellers
                          </h3>
                          <p className="fs-18 text-black">
                            U-Trade empowers sellers, including importers and
                            manufacturers, by providing a direct channel to
                            showcase and market your products, allowing
                            increased visibility and sales. Join us to enhance
                            your reach to buyers and boost your business.
                          </p>
                          <div className="mb-10">
                            <Link
                              to="/sellers"
                              className="more hover text-black"
                            >
                              Learn More
                            </Link>
                          </div>
                        </div>
                        <img
                          className="img-fluid"
                          src={IMAGES.macbookMockup1}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="card testimonials justify-content-end bg-white text-center">
                        <div className="card-body pb-0 mt-md-4 mt-2">
                          <h3 className="h1 post-title fs-38 mb-3">
                            For Buyers
                          </h3>
                          <p className="fs-18 text-black">
                            Buyers are at the forefront of our innovation.
                            Discover a vast marketplace of products from trusted
                            sellers and enjoy a user-friendly experience akin to
                            B2C shopping, making procurement effortless. Start
                            buying smarter with U-Trade.
                          </p>
                          <div className="mb-10">
                            <Link
                              to="/buyers"
                              className="more hover text-black"
                            >
                              Learn More
                            </Link>
                          </div>
                        </div>
                        <img
                          className="img-fluid"
                          src={IMAGES.iphoneMockup1}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container py-md-8 py-6">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-5 order-lg-2">
              <img
                className="img-fluid"
                src={IMAGES.whoWeAre as any}
                alt=""
              />
            </div>
            <div className="col-lg-7">
              <h3 className="display-4 fs-42 fw-bold text-black mb-md-2 mb-1">
                Who we are
              </h3>
              <p className="lead">
                Our journey began with a vision to revolutionise how businesses
                connect, trade, and prosper. Driven by this desire to simplify
                and streamline complex supply chains, we set out to create a
                robust and secure space where B2B buyers and sellers could
                interact directly, bypassing unnecessary intermediaries.
              </p>
              <p className="lead">
                Guided by the principles of transparency, trust, and
                transformation, we are a team of innovators and dreamers,
                committed to empowering both sellers and buyers to trade
                smarter, quicker, and more profitably.
              </p>
            </div>
          </div>
        </section>
        <section className="container py-md-8 py-6">
          <div className="card bg-primary">
            <div className="card-body p-9 p-xl-10">
              <div className="row">
                <div className="col-xl-11 mx-auto">
                  <div className="row align-items-center counter-wrapper gy-4 text-center">
                    <div className="col-6 col-lg-3">
                      <h3 className="counter fs-62 text-white">273+</h3>
                      <p className="fs-20 text-white mt-3">Verified sellers</p>
                    </div>
                    <div className="col-6 col-lg-3">
                      <h3 className="counter fs-62 text-white">1000+</h3>
                      <p className="fs-20 text-white mt-3">Verified buyers</p>
                    </div>
                    <div className="col-6 col-lg-3">
                      <h3 className="counter fs-62 text-white">75%</h3>
                      <p className="fs-20 text-white mt-3">
                        Yearly business growth
                      </p>
                    </div>
                    <div className="col-6 col-lg-3">
                      <h3 className="counter fs-62 text-white">99%</h3>
                      <p className="fs-20 text-white mt-3">
                        Satisfaction ratio
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container py-md-8 py-6">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-5">
              <img
                className="img-fluid"
                src={IMAGES.whatWeDo as any}
                alt=""
              />
            </div>
            <div className="col-lg-7">
              <h3 className="display-4 fs-42 fw-bold text-black mb-md-2 mb-1">
                What we do
              </h3>
              <p className="lead">
                At U-Trade, We understand the challenges businesses face in the
                traditional B2B trade model, and we are here to provide a
                solution. By connecting sellers, including importers and
                manufacturers, with a global audience of eager buyers, our
                dynamic marketplace removes around 6-7 intermediaries that are
                present in the traditional trade model, facilitating direct
                transactions and lowering costs.
              </p>
              <p className="lead">
                Furthermore, we offer a user-friendly interface that feels more
                like B2C shopping, making the procurement process intuitive and
                efficient. From showcasing products to tracking orders, we've
                streamlined every step of the trade journey for every party
                involved.
              </p>
            </div>
          </div>
        </section>
        {/* <section className='py-md-8 py-6'>
                    <div className="wrapper bg-gray">
                        <div className="container py-md-13 py-11">
                            <div className="row justify-content-center">
                                <div className="col-lg-9">
                                    <h2 className="display-4 fs-38 fw-bold mb-3 text-center">Frequently asked questions!</h2>
                                    <p className="lead fs-20 fw-500 text-center mb-8">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et lorem ipsum dolor.</p>
                                </div>
                                <div className="col-lg-12">
                                    <Accordion className="accordion-wrapper custom">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <Accordion.Item className='card border mb-3' eventKey="0">
                                                    <Accordion.Header className='card-header fs-20 fw-600'>Title of this section will be replace here with actual text.</Accordion.Header>
                                                    <Accordion.Body className='pt-0'>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item className='card border mb-3' eventKey="1">
                                                    <Accordion.Header className='card-header fs-20 fw-600'>Title of this section will be replace here with actual text.</Accordion.Header>
                                                    <Accordion.Body className='pt-0'>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item className='card border mb-3' eventKey="2">
                                                    <Accordion.Header className='card-header fs-20 fw-600'>Title of this section will be replace here with actual text.</Accordion.Header>
                                                    <Accordion.Body className='pt-0'>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-lg-6">
                                                <Accordion.Item className='card border mb-3' eventKey="4">
                                                    <Accordion.Header className='card-header fs-20 fw-600'>Title of this section will be replace here with actual text.</Accordion.Header>
                                                    <Accordion.Body className='pt-0'>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item className='card border mb-3' eventKey="5">
                                                    <Accordion.Header className='card-header fs-20 fw-600'>Title of this section will be replace here with actual text.</Accordion.Header>
                                                    <Accordion.Body className='pt-0'>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item className='card border mb-3' eventKey="6">
                                                    <Accordion.Header className='card-header fs-20 fw-600'>Title of this section will be replace here with actual text.</Accordion.Header>
                                                    <Accordion.Body className='pt-0'>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                        </div>
                                    </Accordion>
                                </div>
                                <div className="col-lg text-center mt-md-5 mt-3">
                                    <Button type='button' className='btn btn-primary btn-lg'>
                                        View all
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
        <section className="pt-md-8 pt-6">
          <div className="container">
            <Testimonials />
          </div>
        </section>
      </div>
    </>
  );
};
export default Home;
