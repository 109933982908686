import { Route, Routes, useLocation } from 'react-router-dom';
import Home from '../pages/home';
import ForSellers from '../pages/for-sellers';
import ForBuyers from '../pages/for-buyers';
import AboutUs from '../pages/about-us';
import Faqs from '../pages/faqs';
import ContactUs from '../pages/contact-us';
import { useEffect } from 'react';
const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};
const AppRoutes = () => (
  <>
    <ScrollToTop />
    <Routes>
      <Route
        path="/"
        element={<Home />}
      />
      <Route
        path="sellers"
        element={<ForSellers />}
      />
      <Route
        path="buyers"
        element={<ForBuyers />}
      />
      <Route
        path="about-us"
        element={<AboutUs />}
      />
      <Route
        path="faqs"
        element={<Faqs />}
      />
      <Route
        path="contact-us"
        element={<ContactUs />}
      />
    </Routes>
  </>
);
export default AppRoutes;
