import React from 'react';
import { Accordion } from 'react-bootstrap';
const Faqs = () => {
  return (
    <>
      <div className="content-wrapper">
        <section className="wrapper image-wrapper bg-soft-ash">
          <div className="container pt-12 pt-md-14 pb-5 pb-md-7 text-center">
            <div className="row">
              <div className="col-lg-10 col-xl-9 col-xxl-8 mx-auto">
                <h1
                  className="display-1 fs-48 fw-bold"
                  style={{
                    animationName: 'slideInDown',
                    animationDuration: '700ms',
                    animationTimingFunction: 'ease',
                    animationDelay: '0ms',
                    animationDirection: 'normal',
                    animationFillMode: 'both',
                  }}
                >
                  {' '}
                  Frequently Asked Questions
                </h1>
              </div>
            </div>
          </div>
          <div className="overflow-hidden">
            <div className="divider text-light mx-n2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 60"
              >
                <path
                  fill="currentColor"
                  d="M0,0V60H1440V0A5771,5771,0,0,1,0,0Z"
                />
              </svg>
            </div>
          </div>
        </section>
        <section className="wrapper bg-light">
          <div className="container py-14 py-md-16">
            <div className="row">
              <div className="col-lg-6 mb-0">
                <Accordion
                  className="accordion-wrapper custom"
                  //   defaultActiveKey="0"
                >
                  <Accordion.Item
                    className="card border bg-soft-ash"
                    eventKey="0"
                  >
                    <Accordion.Header className="card-header fs-20 fw-600">
                      How Do I Register as a Seller on U-Trade?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                      Get started on U-Trade by completing our simple seller
                      registration process. It’s quick and straightforward.
                      Alternatively, you can also request us to create an
                      account for you. Join our community of successful sellers
                      today.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="card border bg-soft-ash"
                    eventKey="1"
                  >
                    <Accordion.Header className="card-header fs-20 fw-600">
                      Can I Sell on U-Trade Without a Website?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                      Absolutely! You don't need a website to start selling on
                      U-Trade. We provide a user-friendly platform to showcase,
                      advertise and sell your products.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="card border bg-soft-ash"
                    eventKey="2"
                  >
                    <Accordion.Header className="card-header fs-20 fw-600">
                      Who Manages the Shipping Process?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                      U-Trade takes care of the shipping process for you. We
                      ensure secure and reliable delivery, allowing you to focus
                      on growing your business.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="card border bg-soft-ash"
                    eventKey="3"
                  >
                    <Accordion.Header className="card-header fs-20 fw-600">
                      How Do I List My Products on U-Trade?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                      Listing your products on U-Trade is easy. Follow the
                      simple instructions on the listing page, and if you face
                      any problems feel free to connect with us. Use our
                      intuitive platform to showcase your offerings and reach a
                      wider audience.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="card border bg-soft-ash"
                    eventKey="4"
                  >
                    <Accordion.Header className="card-header fs-20 fw-600">
                      What Strategies can I Implement to Grow My Business on
                      U-Trade?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                      Explore our seller resources and tips to maximise your
                      success on U-Trade. We provide insights and tools to help
                      you expand your business. Furthermore, you can create
                      targeted advertisements and reach a wider audience more
                      effectively.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="card border bg-soft-ash"
                    eventKey="5"
                  >
                    <Accordion.Header className="card-header fs-20 fw-600">
                      Is there a Fee for Registering as a Seller on U-Trade?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                      Registration as a seller on U-Trade is free! You can join
                      our platform and start selling without any upfront fees.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-lg-6">
                <Accordion
                  className="accordion-wrapper custom bg-light"
                  defaultActiveKey="4"
                >
                  <Accordion.Item
                    className="card border bg-soft-ash"
                    eventKey="0"
                  >
                    <Accordion.Header className="card-header fs-20 fw-600">
                      How Do I Create a Buyer Account on U-Trade?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                      You can easily create a buyer account on U-Trade by following our registration process. Alternatively, you can also request us to do that for you. Join our platform to access a world of B2B commerce opportunities.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="card border bg-soft-ash"
                    eventKey="1"
                  >
                    <Accordion.Header className="card-header fs-20 fw-600">
                      What Types of Products can I Find on U-Trade?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                      U-Trade offers a diverse range of products from trusted
                      suppliers. Explore our catalogue to find everything from
                      food and clothing to electronics and industrial equipment.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="card border bg-soft-ash"
                    eventKey="2"
                  >
                    <Accordion.Header className="card-header fs-20 fw-600">
                      How Does U-Trade Ensure Secure Payments for Buyers?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                      We prioritise secure payment processing to protect buyers.
                      Our platform employs advanced security measures, giving
                      you confidence in your transactions.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="card border bg-soft-ash"
                    eventKey="3"
                  >
                    <Accordion.Header className="card-header fs-20 fw-600">
                      Can I Track My Orders As I Do in B2C Shopping?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                      Yes, you can! U-Trade provides order tracking features
                      similar to B2C shopping. Monitor your orders in real-time
                      for a hassle-free buying experience.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="card border bg-soft-ash"
                    eventKey="4"
                  >
                    <Accordion.Header className="card-header fs-20 fw-600">
                      What is the Refund and Cancellation Policy on U-Trade?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                      We offer buyer-friendly refunds as well as cancellation
                      policies, enabling you to make decisions without any
                      hesitation. Learn more about{' '}
                      <span className="text-primary">
                        U-Trade's refund and cancellation policy
                      </span>{' '}
                      to understand your options in case of changes to your
                      orders.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="card border bg-soft-ash"
                    eventKey="5"
                  >
                    <Accordion.Header className="card-header fs-20 fw-600">
                      Is there a Registration Fee to Join U-Trade as a Buyer?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0 fs-18 fw-500 text-black">
                      No, there is no registration fee to join U-Trade. Getting
                      started on our platform is simple and free. You can
                      register as a buyer without any upfront costs and explore
                      the benefits of our B2B commerce ecosystem.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              {/*/column */}
            </div>
            {/*/.row */}
          </div>
          {/* /.container */}
        </section>
      </div>
    </>
  );
};
export default Faqs;
