import { IMAGES } from '../../utils/staticJSON';
import { Splide, SplideSlide } from '@splidejs/react-splide';

// splider >> CSS
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/sea-green';
import '@splidejs/react-splide/css/core';

const Testimonials = () => {

    return (

        <>

            <div className="row justify-content-center">

                <div className="col-lg-11">
                    <h2 className="display-4 fs-38 fw-bold mb-2 text-center">Eliminate inefficiencies and foster partnerships that drive success</h2>
                    <p className="lead fs-20 fw-500 mb-2 text-center">Read how our sellers and buyers have benefitted from our platform, taking their business to new heights of success.</p>
                </div>

                <div className="col-lg-12">
                    <Splide
                        className='px-0 py-md-8 py-6'
                        options={{
                            arrows: false,
                            height: 'auto',
                            autoplay: true,
                            rewind: true,
                            gap: '1rem',
                            perPage: 3,
                            pagination: false,
                            breakpoints: {
                                640: {
                                    perPage: 1,
                                    pagination: true,
                                },
                                767: {
                                    perPage: 2,
                                    pagination: true,
                                },
                                992: {
                                    perPage: 1,
                                    pagination: true,
                                },
                                1024: {
                                    perPage: 2,
                                    pagination: true,
                                }
                            },
                            updateOnMove: true,
                        }}
                    >
                        <SplideSlide>
                            <div className="card testimonials">
                                <div className="card-body px-6 pt-6 pb-0">
                                    <blockquote className="border-0 mb-0">
                                        <p className='fs-18 text-black'>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam volup. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam volup. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam Lorem ipsum dolor sit amet, consetetur sadipscing.
                                        </p>
                                    </blockquote>
                                </div>
                                <div className="card-footer border-top-0 pt-0 px-6 pb-6">
                                    <div className="blockquote-details">
                                        <img
                                            className="rounded-circle border w-11"
                                            src={IMAGES.a1} alt=""
                                        />
                                        <div className="info">
                                            <h5 className="fs-16 mb-0">Aunali Mohamed Abdulrazak</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SplideSlide>
                        <SplideSlide>
                            <div className="card testimonials">
                                <div className="card-body px-6 pt-6 pb-0">
                                    <blockquote className="border-0 mb-0">
                                        <p className='fs-18 text-black'>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
                                        </p>
                                    </blockquote>
                                </div>
                                <div className="card-footer border-top-0 pt-0 px-6 pb-6">
                                    <div className="blockquote-details">
                                        <img
                                            className="rounded-circle border w-11"
                                            src={IMAGES.a2} alt=""
                                        />
                                        <div className="info">
                                            <h5 className="fs-16 mb-0">Bianca Ross</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SplideSlide>
                        <SplideSlide>
                            <div className="card testimonials">
                                <div className="card-body px-6 pt-6 pb-0">
                                    <blockquote className="border-0 mb-0">
                                        <p className='fs-18 text-black'>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
                                        </p>
                                    </blockquote>
                                </div>
                                <div className="card-footer border-top-0 pt-0 px-6 pb-6">
                                    <div className="blockquote-details">
                                        <img
                                            className="rounded-circle border w-11"
                                            src={IMAGES.a3} alt=""
                                        />
                                        <div className="info">
                                            <h5 className="fs-16 mb-0">Patrick Richards</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SplideSlide>
                        <SplideSlide>
                            <div className="card testimonials">
                                <div className="card-body px-6 pt-6 pb-0">
                                    <blockquote className="border-0 mb-0">
                                        <p className='fs-18 text-black'>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam volup. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam volup. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam Lorem ipsum dolor sit amet, consetetur sadipscing.
                                        </p>
                                    </blockquote>
                                </div>
                                <div className="card-footer border-top-0 pt-0 px-6 pb-6">
                                    <div className="blockquote-details">
                                        <img
                                            className="rounded-circle border w-11"
                                            src={IMAGES.a4} alt=""
                                        />
                                        <div className="info">
                                            <h5 className="fs-16 mb-0">Jamani Boro</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SplideSlide>
                    </Splide>
                </div>

            </div>


        </>

    )

}

export default Testimonials;